import {Observable, of} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {NotificationService} from './notification.service'
import {environment} from '../environments/environment';
import {isPlatformBrowser} from '@angular/common';
import {ConstantsService as ConstantsHttpService, GetConstants200ResponseApiModel} from 'api-client';

@Injectable({
  providedIn: 'root'
  })
export class ConstantsService {
    public baseUrl = environment.baseUrl;

    public payment_public_key: string;
    public podioAppId: string;
    public redirectUri: string;
    public registerUri: string;
    public host: string;
    public stripeBaseUrl: string;

    private constants: Partial<GetConstants200ResponseApiModel>;


    private constantsRequest: Observable<Partial<GetConstants200ResponseApiModel>>;

    constructor(private constantsHttpService: ConstantsHttpService, notify: NotificationService, @Inject(PLATFORM_ID) private platformId: Object) {

      this.constantsRequest = this.constantsHttpService.getConstants().pipe(
          catchError(error => {
            notify.notify(error.message, 'Could not load constants!', 'error');
            return of({});
          }),
          shareReplay(1)
        );
        this.constantsRequest.subscribe(result => {
            this.constants = result;
            this.podioAppId = result.podioAppId;
            this.payment_public_key = result.payment_publik_key;
            this.redirectUri = result.redirectUri;
            this.registerUri = result.registerUri;
            this.stripeBaseUrl = result.stripeBaseUrl;
        });
        if (isPlatformBrowser(this.platformId)) {
          this.host = window.location.host; // could be wrapped/injected for testing..
        }
    }

    getAsObservable(value: string) {
        if (this.constants) {
            return of(this.constants[value]);
        } else {
            return this.constantsRequest.pipe(map(res => res[value]));
        }
    }

}
